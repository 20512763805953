import React from "react"

import GlobalCollection from "../config/GlobalCollection"
import Zelfvertrouwen from "../modules/specialisaties/Zelfvertrouwen"

const ZelfvertrouwenPage = () => (
  <GlobalCollection>
    <Zelfvertrouwen />
  </GlobalCollection>
)

export default ZelfvertrouwenPage
