import React from "react"
import styled from "../../config"
import { Flex, Box } from "reflexbox"
import TextBlock from "../../components/TextBlock"
import Blob from "../../components/Blob"
import { Link } from "gatsby"

const Depressie = () => (
  <DepressieWrapper>
    <Blob cheight="250px" />
    <Flex justifyContent="center" py={5}>
      <h1>Zelfvertrouwen</h1>
    </Flex>
    <Box p={3}>
      <StyledLink to="/specialisaties"> Terug</StyledLink>
      <TextBlock className="slanted centered">
        “Liefde voor jezelf is het uitgangspunt om een persoon te ontwikkelen
        die de moed voelt om de verantwoordelijkheid te nemen voor zijn eigen
        bestaan.”
      </TextBlock>
      <TextBlock className="slanted centered">-Viktor Frankl-</TextBlock>
      <h2>Wat kan je van de therapie verwachten?</h2>
      <TextBlock>
        We zijn allemaal uniek en elke therapie wordt dan ook op jouw wensen en
        doelen afgesteld. Enkele resultaten kunnen zijn:
      </TextBlock>
      <ul>
        <li>Jouw persoonlijk doel concreet maken.</li>
        <li>Je kan je gedachten observeren en managen.</li>
        <li>Je kan genieten van jezelf en anderen.</li>
        <li>Je leeft vanuit je talenten en je innerlijke kracht.</li>
        <li>Je durft je mening uiten.</li>
        <li>Je kan problemen aanpakken.</li>
      </ul>
      <TextBlock>
        Zelfvertrouwen is het{" "}
        <b>gevoel van eigenwaarde en het vertrouwen in je eigen kracht</b>. Men
        heeft vertrouwen in zichzelf in dat wat hij of zij kan of wie hij/zij
        is. Het is de combinatie van onze zelfverzekerdheid en ons zelfrespect.
        Het vertrouwen dat je op eigen kracht taken aankunt en tegenvallers het
        hoofd kunt bieden. Zelfvertrouwen is het beeld dat we van onszelf
        hebben, de manieren waarop we onszelf en anderen begrijpen. Daarom is
        het tegelijkertijd ook ons vermogen om van onszelf te houden, onszelf te
        waarderen, voor onszelf te zorgen en onszelf te respecteren. Het
        ondersteunt het fundament van onze identiteit.
      </TextBlock>
      <TextBlock>
        We hebben een tekort aan zelfvertrouwen als het idee dat we over onszelf
        hebben negatief is en minderwaardig. Dat heeft dan weer een invloed op
        hoe we in het leven staan en de keuzes die we maken. Mensen met weinig
        zelfvertrouwen worden vaak overmeesterd door gevoelens van afwijzing,
        ontevredenheid en minachting ten opzichte van zichzelf. Het mag
        duidelijk zijn dat het hier gaat over wat je over jezelf denkt en voelt
        en niet over hoe anderen over jou denken.{" "}
        <b>
          Zonder een bepaalde mate van zelfvertrouwen kan je aan veel van je
          behoeftes niet voldoen
        </b>
        . Jezelf beoordelen en afwijzen veroorzaakt veel pijn. Mensen met een
        lage zelfwaardering proberen zichzelf te beschermen tegen deze
        emotionele pijn. Het neerkijken op jezelf en het niet opkomen voor
        jezelf maakt dat je extra kwetsbaar bent voor kritiek van anderen en ga
        je steeds vaker sociaal contact vermijden.
      </TextBlock>
      <TextBlock>
        Herken je jezelf in bovenstaande? We kunnen{" "}
        <b>
          samen op onderzoek gaan welke negatieve waarden je aan jezelf geeft
        </b>{" "}
        en hoe we die we die kunnen omzetten in positieve gedachten. Het doel is
        de kracht die in jou zit naar boven te halen. Het heft in handen nemen
        en je leven organiseren zoals jij het wilt.
      </TextBlock>
      <TextBlock>
        Heb je extra vragen of wil je een afspraak maken, aarzel dan niet om
        contact met me op te nemen.
      </TextBlock>
    </Box>
  </DepressieWrapper>
)

export default Depressie

const DepressieWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px;
`

const StyledLink = styled(Link)`
  text-decoration: underline;
  color: #00628f;
  display: block;
  margin-bottom: 25px;
  font-size: 1.1em;
`
